import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

// slider carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

// const IndexPage = () => (

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalContacts: 250,
      requiredSrcNums: 2,
      totalSmsParts: 2,
      totalMmsParts: 0,
      contactsPerSrcNum: 150,
      pricePerSmsPart: 0.05,
      pricePerMmsPart: 0.08,
      pricePerSrcNum: 2,
      isCalcOpen: true,
      openFaqId: "",
      email: "",
    }
  }

  handleChange(e) {
    // console.log(`${e.target.name}: ${e.target.value}`)
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    const totalChatMsgs = 3
    const delayTime = 800 //milliseconds btw chat msgs appearing
    for (let i = 0; i < totalChatMsgs; i++) {
      setTimeout(() => {
        const chatMsg = document.getElementById("chatMsg" + i)
        if (chatMsg) chatMsg.style.opacity = 1
        //  chatMsg.style.marginTop = '0px';
        //  chatMsg.style.marginLeft = '4px';
        //  chatMsg.style.marginRight = '4px';
      }, delayTime * (i + 1))
    }
  }

  faqArrow(faqId) {
    // console.log("faqArrow: ", faqId)
    if (this.state.openFaqId !== faqId) {
      // console.log("faqArrow nomatch: ", faqId)
      return <i className="material-icons faqIcon">keyboard_arrow_down</i>
    } else {
      // console.log("faqArrow match: ", faqId)
      return <i className="material-icons faqIcon tgrey3">keyboard_arrow_up</i>
    }
  }

  faqToggle(faqId) {
    this.setState({
      openFaqId: this.state.openFaqId === faqId ? "" : faqId,
    })
  }

  toggleCalc() {
    const calc = document.getElementById("toggleCalc")
    if (!calc) return
    let calcClass = calc.className
    if (calcClass.indexOf("openCalc") !== -1) {
      calcClass = calcClass.replace(" openCalc", "")
    } else {
      calcClass = calcClass + " openCalc"
    }
    calc.className = calcClass
  }

  render() {
    let now = new Date()
    let hour = now.getHours()
    let ampm = "PM"
    if (hour < 12) ampm = "AM"
    if (hour > 12) hour = hour - 12
    let min = now.getMinutes()
    if (min < 10) min = "0" + min

    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `VantageSMS`,
            `SMS`,
            `Text`,
            `Texting`,
            `Small`,
            `Business`,
            `Online`,
            `Texting`,
            `Messaging`,
            `Marketing`,
            `Campaign`,
          ]}
        />

        {/* HERO */}
        <div className="hero1">
          <div className="content">
            <div className="flexContainer">
              <div className="card">
                <h1>
                  SMS Marketing
                  <br /> Made Easy
                </h1>
                {/* <p>A Fully Managed SMS Marketing Service.</p> */}
                {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
                {/* <hr /> */}
                {/* <p>A Fully Managed SMS Marketing Service</p> */}
                {/* <p>
          Have a text message you want to broadcast out?
          <br /> Just contact us and we take care of the rest!
        </p> */}
                {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
                <p>
                  Need to text to your customers about an upcoming event or
                  promotion?
                </p>
                <hr />
                <p>Contact us and we'll handle it for you!</p>
                <EmailCapture />
                {/* <EmailRedirect /> */}
                {/* <p>
                  
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    placeholder="your.name@email.com"
                    onChange={e => this.handleChange(e)}
                  />
                  <br />
                  <Link to={`/start/`} state={{ email: this.state.email }}>
                    <button className="">Get Started</button>
                  </Link>

                  <button className="">Yes, Contact Me</button>
                </p> */}
                {/* <div className="">
                  <form
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <p className="noHeight oHidden m0">
                      <label>
                        Don’t fill this out if you're human:
                        <input name="bot-field" />
                      </label>
                    </p>
                    <p className="mb0">
                      <label>
                        Your Name:{" "}
                        <input
                          type="text"
                          name="name"
                          placeholder="Full Name"
                        />
                      </label>
                    </p>
                    <p style={{ marginTop: "-4px" }}>
                      <label>
                        Your Email:{" "}
                        <input
                          type="email"
                          name="email"
                          placeholder="you@email.com"
                        />
                      </label>
                    </p>
                    <p>
                      <button type="submit">Get Started</button>
                    </p>
                  </form>
                </div> */}
              </div>
              <div className="card taCenter">
                <div className="iphone">
                  <div className="mask mask__noimage">
                    {/* <img alt="" className="mask-img"/> */}
                    <div className="phoneTopRow taCenter">
                      <span className="fleft">
                        &nbsp; &#8226;&#8226;&#8226;&#8226;&#8226;
                      </span>
                      <span>
                        {hour}:{min} {ampm}
                      </span>
                      <span className="fright">82%&nbsp; </span>
                    </div>
                    <div className="phoneNameRow taCenter">
                      <span className="fleft">&nbsp; &#60;</span>
                      +1 (234) 555-6789
                      <span className="fright">&#9432;&nbsp; </span>
                    </div>
                    <div id="chatMsgX" className="chat cLeft">
                      Hi Jane, this is John from NuCo. Just wanted to let you
                      know we have a sale going on right now. When you buy 2
                      widgets, you get a 3rd one free!{" "}
                      <span role="img" aria-label="raised-hands">
                        🙌
                      </span>
                    </div>
                    <div
                      id="chatMsg0"
                      className="chat cRight"
                      style={{ opacity: 0 }}
                    >
                      Wow, that sounds great! Thanks for letting me know.{" "}
                      <span role="img" aria-label="smile">
                        😃
                      </span>
                      I'll stop by tomorrow. When are you open to?
                    </div>
                    <div
                      id="chatMsg1"
                      className="chat cLeft"
                      style={{ opacity: 0 }}
                    >
                      Excellet! We're open till 9pm tomorrow. See you then.{" "}
                      <span role="img" aria-label="wave">
                        👋
                      </span>
                    </div>
                    <div
                      id="chatMsg2"
                      className="chat cRight"
                      style={{ opacity: 0 }}
                    >
                      Perfect, see you then.{" "}
                      <span role="img" aria-label="thumb-up">
                        👍
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PROMO BAR */}
        <div className="hero2">
          <div className="content">
            <h1>
              The easiest way to promote
              <br /> your business or organization.
            </h1>
            <p>
              SMS is up to 70x faster than calling and 5x more effective than
              email.{" "}
              <span role="img" aria-label="muscle">
                💪
              </span>
            </p>
          </div>
        </div>

        {/* HOW IT HELPS */}
        <div className="hero3">
          <div className="content">
            <h1>How Vantage Helps</h1>
            <div className="flexContainer">
              <div className="card">
                <p>
                  <i className="material-icons">drafts</i>
                </p>
                <h2>Higher Open Rates</h2>
                <p>
                  95% of text messages are read within 3 minutes of receiving
                  them.{" "}
                  <span role="img" aria-label="surprise">
                    😮
                  </span>
                </p>
              </div>
              <div className="card">
                <p>
                  <i className="material-icons">mood</i>
                </p>
                <h2>Increased Engagement</h2>
                <p>
                  85% of consumers want the ability to receive and reply to a
                  business or organization via text messages.{" "}
                  <span role="img" aria-label="chat-bubble">
                    💬
                  </span>
                </p>
              </div>
              <div className="card">
                <p>
                  <i className="material-icons">done_outline</i>
                </p>
                <h2>Fully Managed</h2>
                <p>
                  We manage the service for you so don't need to spend time
                  learning or using new software.{" "}
                  <span role="img" aria-label="raised-hands">
                    🙌
                  </span>
                </p>
              </div>
              <div className="card">
                <p>
                  <i className="material-icons">cake</i>
                </p>
                <h2>Time Saved</h2>
                <p>
                  SMS broadcasts are up to 70x faster than calling and 5x more
                  effective than email.{" "}
                  <span role="img" aria-label="lightning">
                    ⚡
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* HOW IT WORKS */}
        <div className="hero4" style={{ display: "none" }}>
          <div className="content">
            <h1>How it Works</h1>
            <br />
            <div className="flexContainer">
              <div className="card desktopIcon">
                <p>
                  <i className="material-icons">mail</i>
                </p>
              </div>
              <div className="card">
                <p className="mobileIcon">
                  <i className="material-icons">mail</i>
                </p>
                <h2>1. Contact Us</h2>
                <p>
                  The first step is to fill out our email contact form. We'll
                  get in touch with you to get started.
                </p>
                {/* <p>
            The first step is to fill out our email contact form. We'll get in
            touch with you to get started and answer any additional questions
            you may have.
          </p> */}
              </div>
              <div className="card">
                <p className=" mobileIcon">
                  <i className="material-icons">group</i>
                </p>
                <h2>2. Share Your Contact List</h2>
                <p>
                  Next, you just send us your contact list with the mobile phone
                  numbers that you want to reach.
                </p>
              </div>
              <div className="card desktopIcon">
                <p>
                  <i className="material-icons">group</i>
                </p>
              </div>
              <div className="card desktopIcon">
                <p>
                  <i className="material-icons">chat</i>
                </p>
              </div>
              <div className="card">
                <p className="mobileIcon">
                  {/* <i className="material-icons">record_voice_over</i> */}
                  <i className="material-icons">chat</i>
                </p>
                <h2>3. Send Us Your Message</h2>
                {/* <p>
            We'll contact you each month to ask what message you'd like sent
            out. We also have message templates to help in case you get writer's
            block!
          </p> */}
                {/* <p>
            We'll contact you each month to ask if there's a message you'd like
            sent out. We even share message samples to give you ideas.
          </p> */}
                <p>
                  We'll contact you each month to ask if there's a message you'd
                  like sent out. We even share{" "}
                  <a href="https://samples.vantagesms.com">message samples</a>{" "}
                  in case you get writer's block!
                </p>
              </div>
              <div className="card">
                <p className="mobileIcon">
                  <i className="material-icons">thumb_up</i>
                  {/* <i className="material-icons">beenhere</i> */}
                </p>
                <h2>4. That's It!</h2>
                {/* <p>
            You'll be emailed a report about 1 week after a broadcast that
            outlines delivery statistics.
            <br />
            <br />
            Any 2-way (inbound) messages you get will be forwarded to your email
            where you can respond.
          </p> */}
                <p>
                  You'll be emailed a report and invoice about 1 week after your
                  broadcast that outlines delivery statistics. Any inbound
                  messages you receive are forwarded to your email.
                </p>
                {/* <p>
            You'll be emailed a report about 1 week after a broadcast that
            outlines delivery statistics. Any inbound messages you get will be
            forwarded to your email where you can respond.
          </p> */}
              </div>
              <div className="card desktopIcon">
                <p>
                  <i className="material-icons">thumb_up</i>
                  {/* <i className="material-icons">beenhere</i> */}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* SLIDER */}
        <div className="slider">
          <div className="content">
            <Carousel
              showThumbs={false}
              showStatus={false}
              swipeable={true}
              emulateTouch={true}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000} //default 3000 ms
              className="grab"
            >
              {/* <div className="">
              <img src="assets/1.jpeg" />
                <p className="legend">1</p>
            </div> */}
              <div>
                <p className="slideTitle">
                  "This is my most effective and least expensive form of
                  advertising"
                </p>
                <p className="slideAuthor">Customer Feedback</p>
              </div>
              <div>
                <p className="slideTitle">
                  "We've sold more product than we've ever sold before using
                  Vantage"
                </p>
                <p className="slideAuthor">Customer Feedback</p>
              </div>

              <div>
                <p className="slideTitle">
                  {/* "Our customers love engaging with us over text messages" */}
                  {/* "Vantage makes it so much easier for me to engage with my
                  customers over text messages." */}
                  "Vantage makes it so much easier to engage with my customers
                  using text messages."
                </p>
                <p className="slideAuthor">Customer Feedback</p>
              </div>
            </Carousel>
          </div>
        </div>

        {/* PRICING INFO & CALC */}
        <div className="pricing">
          <div className="content">
            <h1>Pricing</h1>
            <h2>Simple and Affordable</h2>
            <br />
            <div className="flexContainer">
              <div className="flexCard">
                {/* COST CALCULATOR */}
                <div id="toggleCalc" className={`card defaultCalc`}>
                  <div
                    className="cardSection taCenter pointer"
                    onClick={() => this.toggleCalc()}
                    // onClick={e =>
                    //   this.setState({ isCalcOpen: !this.state.isCalcOpen })
                    // }
                  >
                    <h1>
                      Cost Calculator{" "}
                      <i className="material-icons calcIconClosed">
                        keyboard_arrow_down
                      </i>
                      <i className="material-icons calcIconOpen">
                        keyboard_arrow_up
                      </i>
                      {/* {!this.state.isCalcOpen && (
                        <i className="material-icons calcIconOpen">keyboard_arrow_down</i>
                      )}
                      {this.state.isCalcOpen && (
                        <i className="material-icons calcIconClosed">keyboard_arrow_up</i>
                      )} */}
                    </h1>
                  </div>
                  {/* {this.state.isCalcOpen && ( */}
                  <div className="calcBody">
                    <div className="cardSection">
                      <h2>Contacts List:</h2>
                      <div>
                        <input
                          id="totalContacts"
                          name="totalContacts"
                          type="number"
                          value={this.state.totalContacts}
                          min="1"
                          max="50000"
                          onChange={e => this.handleChange(e)}
                        />
                        &nbsp; Contacts
                      </div>
                      {/* <h3>
                          {Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          )}{" "}
                          Source Numbers Required <br />(
                          {this.state.contactsPerSrcNum} Contacts per Source
                          Number)
                        </h3> */}
                    </div>
                    {/* <div className="cardSection">
            <h2>Total Source Numbers:</h2>
            <div>10 Required Numbers</div>
            <h3>150 Contacts per Source Number</h3>
          </div> */}
                    <div className="cardSection">
                      <h2>Message Length:</h2>

                      <div>
                        <input
                          id="totalSmsParts"
                          name="totalSmsParts"
                          value={this.state.totalSmsParts}
                          onChange={e => this.handleChange(e)}
                          type="number"
                          min="1"
                          max="10"
                        />
                        &nbsp; SMS Parts
                      </div>
                      {/* <h3>
                          Broadcast message length is usually 2-3 SMS Parts for
                          most customers.
                        </h3> */}
                      <h3 style={{ margin: "8px 0px 0px" }}>
                        Most broadcasts are 2-3 SMS Parts.
                      </h3>
                    </div>
                    <div className="cardSection">
                      <h2>Picture Messages:</h2>

                      <div>
                        <input
                          id="totalMmsParts"
                          name="totalMmsParts"
                          value={this.state.totalMmsParts}
                          onChange={e => this.handleChange(e)}
                          type="number"
                          min="0"
                          max="10"
                        />
                        &nbsp; MMS Parts
                      </div>
                      {/* <h3>
                          Broadcast message length is usually 0-1 MMS (Picture
                          Message) Parts for most customers.
                        </h3> */}
                    </div>
                    <div className="cardSection bb0">
                      <h2>Total:</h2>

                      <div className="mb8">
                        $
                        {(
                          this.state.totalContacts *
                          this.state.totalSmsParts *
                          this.state.pricePerSmsPart
                        ).toFixed(2)}{" "}
                        <span className="fw400 tgrey3">for</span>{" "}
                        {this.state.totalContacts * this.state.totalSmsParts}{" "}
                        SMS
                      </div>
                      {this.state.totalMmsParts > 0 && (
                        <div className="mb8">
                          $
                          {(
                            this.state.totalContacts *
                            this.state.totalMmsParts *
                            this.state.pricePerMmsPart
                          ).toFixed(2)}{" "}
                          <span className="fw400 tgrey3">for</span>{" "}
                          {this.state.totalContacts * this.state.totalMmsParts}{" "}
                          MMS
                        </div>
                      )}

                      <div>
                        $
                        {(
                          Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          ) * this.state.pricePerSrcNum
                        ).toFixed(2)}{" "}
                        <span className="fw400 tgrey3">for</span>{" "}
                        {Math.ceil(
                          this.state.totalContacts /
                            this.state.contactsPerSrcNum
                        )}{" "}
                        Source Numbers
                      </div>
                      <h3 style={{ margin: "6px 0px 0px" }}>
                        ({this.state.contactsPerSrcNum} Contacts per Source
                        Number)
                      </h3>
                      {/* <h3 className="mb0">(150 Contacts per Source Number)</h3> */}
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="flexCard">
                {/* PRICES LIST */}
                <div className="card pb0">
                  <div className="cardSection taCenter">
                    <h1>Starting From</h1>
                    {/* {!this.state.isCalcOpen && <h1>Starting From</h1>}
                    {this.state.isCalcOpen && <h1>Total Cost</h1>} */}
                    <p className="mb8">
                      {/* <span>$49.00</span> CAD */}
                      <span>
                        $
                        {(
                          this.state.totalContacts *
                            this.state.totalSmsParts *
                            this.state.pricePerSmsPart +
                          this.state.totalContacts *
                            this.state.totalMmsParts *
                            this.state.pricePerMmsPart +
                          Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          ) *
                            this.state.pricePerSrcNum
                        ).toFixed(2)}
                      </span>{" "}
                      CAD
                    </p>
                    <p className="m0">Per Broadcast +HST</p>
                  </div>

                  {/* <div className="cardSection ">
            <p>Usage Based Pricing</p>
            <p>Starting</p>
            <p>
              <i className="material-icons fs20">wifi_tethering</i> &nbsp; $49 CAD
              minimum
            </p>
            <p>
              <i className="material-icons fs20">record_voice_over</i> &nbsp; $49
              CAD per Broadcast minimum
            </p>
          </div> */}

                  <div className="cardSection priceList">
                    {/* <p>Fair Usage Based Pricing</p> */}
                    {/* <p>
              <i className="material-icons fs20">wifi_tethering</i> &nbsp; $49 CAD
              minimum broadcast fee
            </p> */}
                    {/* <p>
              <i className="material-icons fs20">record_voice_over</i> &nbsp; $49
              CAD per Broadcast minimum
            </p> */}
                    <p>
                      <i className="material-icons fs20">chat</i> &nbsp; $0.05
                      CAD per SMS Part
                    </p>
                    <p>
                      <i className="material-icons fs20">photo</i> &nbsp; $0.08
                      CAD per MMS (Picture Message)
                    </p>
                    <p>
                      <i className="material-icons fs20">phone</i> &nbsp; $2.00
                      CAD per Source Number
                    </p>
                  </div>

                  <div className="cardSection bb0">
                    <div className="taCenter">
                      <EmailCapture />
                      {/* <input
                        type="email"
                        name=""
                        placeholder="your.name@email.com"
                        style={{
                          marginTop: "8px",
                          marginBottom: "16px",
                          width: "100%",
                          maxWidth: "100%",
                          padding: "8px 12px",
                          fontWeight: "400",
                        }}
                      />
                      <br />
                      <button
                        className=""
                        style={{
                          padding: "12px 20px",
                        }}
                      >
                        Get Started
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="hero5" style={{ display: "none" }}>
          <div className="content">
            <h1>FAQ</h1>
            <h2>
              Can't find an answer? Please reach out to us at:
              <br />
              <a href="mailto:hello@vantagesms.com">hello@vantagesms.com</a>
            </h2>
            <br />
            <div className="flexContainer">
              {/* {this.setState({
                faqList: [
                  {
                    id: "ltc",
                    title: "Are there long term contracts?",
                    body: `No, this is an on demand service. We don't lock you into
                      long term agreements. You only pay for the broadcast
                      messages you send or receive.`,
                  },
                  {
                    id: "cat",
                    title: "xxx",
                    body: "yyy",
                  },
                  {
                    id: "aaa",
                    title: "xxx",
                    body: "yyy",
                  },
                ],
              })} */}
              <div
                className={`card ${
                  this.state.openFaqId === "ltc" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("ltc")}
              >
                <h3 className="pr40">
                  {this.faqArrow("ltc")}Are there long term contracts?
                </h3>
                {this.state.openFaqId === "ltc" && (
                  <p>
                    No, this is an on demand service. We don't lock you into
                    long term agreements. You only pay for the broadcast
                    messages you send or receive.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "cat" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("cat")}
              >
                <h3 className="pr40">
                  {this.faqArrow("cat")}Can I cancel anytime?
                </h3>
                {this.state.openFaqId === "cat" && (
                  <p>
                    Yes, simply send us an email saying you want to cancel.
                    We'll be sorry to see you go, but we'll get it sorted for
                    you. Note that you will be billed for your current month's
                    usage at time of cancellation.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "hpw" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("hpw")}
              >
                <h3 className="pr40">
                  {this.faqArrow("hpw")}How does your pricing work?
                </h3>
                {this.state.openFaqId === "hpw" && (
                  <p>
                    You are only charged for the SMS, MMS, and Source Numbers
                    used to during your broadcast.
                    <br />
                    <br />
                    For example, if you have 1000 contacts and send a 2 part SMS
                    broadcast, we will invoice you about 1 week after your
                    broadcast for 2000 SMS Messages sent (1000 Contacts * 2 SMS
                    Parts) and 7 Source Numbers (1000 Contacts / 150 Contacts
                    per source number). Plus any SMS/MMS you may have received
                    from your contacts.
                    <br />
                    <br />
                    We have a cost calculator above to help estimate pricing
                    based on your contact list size and broadcast message
                    length.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "uid" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("uid")}
              >
                <h3 className="pr40">
                  {this.faqArrow("uid")}What is a source number?
                </h3>
                {this.state.openFaqId === "uid" && (
                  <p>
                    A source number is the name of a phone number we use to send
                    out your message to your contact list. Normally we purchase
                    1 source number for every 150 contacts in your list to help
                    maximize delivery to your contacts.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "clp" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("clp")}
              >
                <h3 className="pr40">
                  {this.faqArrow("clp")}Is my contact list kept private?
                </h3>
                {this.state.openFaqId === "clp" && (
                  <p>
                    Yes, your contact list is never shared with anyone. It is
                    kept completely private and stored securely on a Google
                    hosted database with strong encryption and authentication
                    protocols in place. In addition, we have included in our
                    Terms that we will never share or sell your contact lists.
                    We take your privacy seriously and work to earn and keep
                    your trust by protecting the privacy and security of any
                    information you share with us.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "rmc" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("rmc")}
              >
                <h3 className="pr40">
                  {this.faqArrow("rmc")}How do I receive messages from my
                  contacts?
                </h3>
                {this.state.openFaqId === "rmc" && (
                  <p>
                    Any inbound messages received from your broadcast will be
                    forwarded to your email along with the sender's contact
                    information.
                    <br />
                    <br />
                    Your reply to that email will be forwarded to the sender as
                    an SMS, or you can use our chat.vantagesms.com web app to
                    chat with your contacts in real time over SMS. Anyone
                    replying with the STOP keyword will be automatically opted
                    out on your behalf. We also include an opt out button in the
                    forwarded email if you need to manually opt out the sender.
                  </p>
                )}
              </div>

              <div
                className={`card ${
                  this.state.openFaqId === "was" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("was")}
              >
                <h3 className="pr40">{this.faqArrow("was")}What about spam?</h3>
                {this.state.openFaqId === "was" && (
                  <p>
                    Sending spam messages is against the law. You must always
                    follow the laws regarding electronic communications in your
                    jurisdiction.
                    <br />
                    <br />
                    For example, in Canada, you must comply with the{" "}
                    <a href="http://www.fightspam.gc.ca/eic/site/030.nsf/eng/00304.html">
                      Canadian Anti Spam Legislation (CASL)
                    </a>{" "}
                    and cannot message your clients from more than two years ago
                    depending on implied or express consent. Any contacts you
                    have that do not meet the implied or express consent
                    standards MUST be removed from your contact list before
                    sharing it with us. If we suspect your message is spam, we
                    will refuse to send it.
                  </p>
                )}
              </div>

              {/* <div
                className={`card ${
                  this.state.openFaqId === "uid" ? "bcblue" : ""
                }`}
                onClick={e => this.faqToggle("uid")}
              >
                <h3 className="pr40">{this.faqArrow("uid")}TITLE</h3>
                {this.state.openFaqId === "uid" && <p>BODY</p>}
              </div> */}

              {/* <div className="card">
                <h3>aaaa</h3>
                <p>bbbbb</p>
              </div> */}
            </div>
          </div>
        </div>

        {/* FOOTER CTA */}
        {/* HERO */}
        <div className="heroFooter taCenter">
          <div className="content">
            {/* <h1>SMS Marketing Made Easy</h1> */}
            <h1>Grow Your Sales</h1>
            {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
            <p>
              Share your contact list and message.
              {/* <br />
          Send us your message. */}
              <br />
              We take care of the rest!
            </p>
            <EmailCapture />
            {/* <p style={{ padding: "0px 20px" }}>
              <input
                type="email"
                name="email"
                value={this.state.email}
                placeholder="your.name@email.com"
                onChange={e => this.handleChange(e)}
              />
              <br />
              <Link to={`/start/`} state={{ email: this.state.email }}>
                <button className="">Get Started</button>
              </Link>
              <button className="">Yes, Contact Me</button>
              <button className="">Yes, Contact Me to Get Started</button>
            </p> */}
          </div>
        </div>

        <div className="" />

        {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
      </Layout>
    )
  }
}

// module.exports = {
//   IndexPage: IndexPage,
// }

export default IndexPage
